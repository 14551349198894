<template>
  <div ref="section1"  id="section1" class="text-section">
    <div class="HeaderTextQuest">
      <h3>WHO WE ARE?</h3>
    </div>
    <div class="textCtn">

      <p>Edujad is an online institute of language learning and teaching.</p>
      <p>It offers both students and instructors intercultural environment in which they can effectively learn languages and discover more about different target cultures, succeed in their career through coaching sessions, and realize their full potential. Inclusiveness is our ultimate goal; we opt for providing language learning to whomever is interested without bearing his economical background.</p>
      <p>We also opt for innovation, passion, and teamwork in our workplace so that the work can be achieved as effective as it should be. If language learning was an obstacle for you, it is never for us. Our teaching is based on “interact, enjoy, and more importantly learn”; Thus, learning will be the most enjoyable step of your successful career.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutSection',
  data() {
    return {
      hash: {
        id: 'section1'
      }
    }
  },


  methods:{
    mounted() {
      if (this.$route.hash === '#section1') {
        this.$nextTick(() => {
          const section = this.$refs.section1;
          if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
          }
        });
      }
    }
  }
};
</script>
<style scoped>
.text-section {
  display: grid;
  grid-template-columns: 30% 50%;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 0 2rem 0;
}

.textCtn {
  flex: 1;
}

.HeaderTextQuest{
  color: #2AA1A1;
}
p{
  padding: 0.5rem;
  text-align: justify;
  line-height: 20pt;
}

</style>