<template>
  <div class="inscription-page">
    <h1>Inscription</h1>
    <form @submit.prevent="handleSubmission">
      <label class="name" for="name">Name:</label>
      <input type="text" id="name" name="name" v-model="name" required>
      <label class="number" for="number">Number:</label>
      <input type="text" id="number" name="number" v-model="number" required>
      <label class="mail" for="mail">Mail:</label>
      <input type="email" id="mail" name="mail" v-model="mail" required>
      <div v-if="!isValidEmail" class="notification error">
      <p>Please enter a valid email address.</p>
    </div>
      <label class="language" for="language">Language:</label>
      <select id="language" name="language" v-model="language" required>
        <option value="">Select a language</option>
        <option value="english">English</option>
        <option value="french">French</option>
        <option value="spanish">Spanish</option>
      </select>
      <label class="note" for="note">Notes:</label>
      <textarea id="note" name="note" v-model="note" required></textarea>
      <button @click="showNotification" class="NavBtn" type="submit">Submit</button>
      <div v-if="showSuccessNotification" class="notification success">
        <p>{{ successMessage }}</p>
      </div>
      <div v-if="showFailNotification && !isValidEmail" class="notification fail">
        <p>Your inscription is not valid. Please check your email address.</p>
      </div>
    </form>
  </div>
</template>

<script>
import { collection, addDoc, getFirestore } from "firebase/firestore";

export default {
  name: 'InscriptionPage',
  data() {
    return {
      name: '',
      number: '',
      mail: '',
      language: '',
      note: '',
      showSuccessNotification: false,
      showFailNotification: false,
      successMessage: 'Inscription completed successfully!',
      isValidEmail: true

    }
  },
  methods: {
    async handleSubmission() {
      // Validate email
      this.isValidEmail = this.validateEmail(this.mail);

      if (this.isValidEmail) {
        try {
          const db = getFirestore();
          const inscriptionData = {
            name: this.name,
            number: this.number,
            language: this.language,
            mail: this.mail,
            note: this.note
          };

          const docRef = await addDoc(collection(db, "inscriptions"), inscriptionData);
          console.log("Document written with ID: ", docRef.id);

          // Reset the form fields
          this.name = "";
          this.number = "";
          this.language = "";
          this.mail = "";
          this.note = "";

          // Show success notification
          this.showSuccessNotification = true;
          this.showFailNotification = false;
          setTimeout(() => {
            this.showSuccessNotification = false;
          }, 3000); // Hide the notification after 3 seconds
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      } else {
        this.showFailNotification = true;
        this.showSuccessNotification = false;
        setTimeout(() => {
          this.showFailNotification = false;
        }, 3000); // Hide the notification after 3 seconds
      }
    },

    validateEmail(email) {
      // Simple email validation regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
  },
}
</script>

<style scoped>
.inscription-page {
  padding: 8rem;
  border-radius: 6px;
  text-align: center;
}
h1{
  color: #2AA1A1;
}

.NavBtn{
  background-color: #2AA1A1;
  border: 0;
  height: 2.3rem;
  width: 5rem;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 15px;
  color: white;
  cursor: pointer;
}
form {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 1rem;
  background-color: #ffffff;
  border-radius: 5px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input, textarea, select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 25rem ;
}
textarea{
  height: 13rem;
}
.notification {
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
}

.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}
</style>