<template>
  <div class="text-section">
    <div class="textCtn">
      <p>Discover the unparalleled advantages of our language learning programs, designed with your utmost convenience. Our flexible offers empower you to shape your learning journey according to your individual preferences, accommodating your free time and aligning seamlessly with your unique learning objectives. With adaptable schedules and personalized curricula, our courses ensure that your language learning experience is not only effective but also tailored to suit your lifestyle, allowing you to achieve fluency at your own pace. Personalize it and take the language test to start learning.</p>
    </div>
    <div class="btn">
      <router-link to="save"> <button class="NavBtn">Send us your audio to test it</button></router-link>
      <router-link to="feedback"> <button class="NavBtn">Take language test</button></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutSection',
  // Add any necessary data, props, etc.
};
</script>
<style scoped>
.text-section {
  justify-content: space-around;
  align-items: center;
  padding: 2rem 0 2rem 0;
  background-color: #2AA1A1;
}

.textCtn {
  flex: 1;
  color: white;
}

p{
  padding: 0.5rem;
  text-align: justify;
  line-height: 20pt;
  font-size: larger;

}
.btn{

}
button{
  margin: 2rem;
  height: 70px;
  width: 200px;
  font-size: large;
  background-color: white;
  color:#2AA1A1;
}
</style>