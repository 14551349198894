<template>
  <div class="contact-page">
    <h1>Contact Us</h1>
    <form @submit.prevent="handleSubmission">
      <label class="name" for="fullname">Full Name:</label>
      <input type="text" id="fullname" name="fullname" v-model="name" required>
      <label class="phone" for="phone">Phone:</label>
      <input type="text" id="phone" name="phone" v-model="phone" >
      <label class="email" for="email">Email:</label>
      <input type="email" id="email" name="email" v-model="email"  required>
      <label class="message" for="message">Message:</label>
      <textarea id="message" name="message" v-model="message"  required></textarea>
      <button @click="showNotification" class="NavBtn" type="submit">Submit</button>
      <div v-if="showSuccessNotification" class="notification success">
        <p>{{ successMessage }}</p>
      </div>
    </form>
  </div>
</template>

<script>
import { collection, addDoc, getFirestore } from "firebase/firestore";

export default {
  name: 'ContactPage',
  data() {
    return {
      fullname: '',
      lastname: '',
      phone: '',
      email: '',
      message: '',
      showSuccessNotification: false,
      successMessage: 'Action completed successfully!',
    }
  },
  methods: {
    async handleSubmission() {
      try {
        const db = getFirestore();
        const userMessage = {
          fullname: this.fullname,
          lastname: this.name,
          phone: this.phone,
          email: this.email,
          message: this.message
        };

        const docRef = await addDoc(collection(db, "userMessage"), userMessage);
        console.log("Document written with ID: ", docRef.id);

        // Reset the form fields
        this.fullname = "";
        this.lastname = "";
        this.phone = "";
        this.email = "";
        this.message = "";
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    },

    showNotification() {
      this.showSuccessNotification = true;
      setTimeout(() => {
        this.showSuccessNotification = false;
      }, 3000); // Hide the notification after 3 seconds
    }
  },
}
</script>

<style scoped>
.contact-page {
  padding: 8rem;
  border-radius: 6px;
  text-align: center;
}
h1{
  color: #2AA1A1;
}
.name input{
  width: 3rem;
}
.lastname input{
  width: 3rem;
}

.NavBtn{
  background-color: #2AA1A1;
  border: 0;
  height: 2.3rem;
  width: 5rem;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 15px;
  color: white;
  cursor: pointer;
}
form {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 1rem;
  background-color: #ffffff;
  border-radius: 5px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input, textarea {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 25rem ;
}
textarea{
  height: 13rem;
}
.notification {
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
}

.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}
</style>