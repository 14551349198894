<template>
    <nav :style="headerStyle" class="nav-container">
      <a href="/" class="logo" >
        <img  v-bind:src="logoSrc" alt="Logo" >
      </a>
      <div>
        <div class="menu-toggle" @click="toggleMenu">☰</div>
        <div class="contentNav" :class="{ 'active': isMenuOpen }">
          <a :style="headerStyle" @click="scrollToSection('home')">Home</a>
          <a :style="headerStyle" @click="goToOffers">Offers</a>
          <a :style="headerStyle" @click="scrollToSection('whoAreWe')">Who Are We?</a>
          <div class="nav-buttons">
            <button :style="buttonStyle"  name="inscription" class="NavBtn" @click="navigateToInscription">Join us!</button>
          </div>
        </div>
      </div>
    </nav>
</template>
<script>
    import router from "@/router";
    import defaultLogo from '../assets/logo.png';
    import scrolledLogo from '../assets/logowhite.png';
    export default {
      name: 'headerMain',
      data() {
        return {
          isMenuOpen: false,
          headerStyle: {
            backgroundColor:'',
            color: 'white',
            textDecoration: 'none'
          },
          buttonStyle:{
            backgroundColor:'#2AA1A1',
            color:'white'

          },
          defaultLogo,
          scrolledLogo,
          logoSrc: defaultLogo,
      };
        },

      methods: {
        toggleMenu() {
          this.isMenuOpen = !this.isMenuOpen;
        },
      navigateToInscription() {
        this.$router.push('/inscription');
      },
        scrollToSection() {
          router.push({ path: '/', hash: '' });
        },
        goToOffers(){
            this.$router.push('/offers');
        },

        handleScroll() {
          if (window.scrollY > 50) {
            this.headerStyle.backgroundColor = '#2AA1A1'; // Change the background color on scroll
            this.headerStyle.color = 'white'; // Change the background color on scroll
            this.logoSrc = this.scrolledLogo;
            this.buttonStyle.backgroundColor= 'white';
            this.buttonStyle.color = '#2AA1A1';
          } else {
            this.headerStyle.backgroundColor = 'white'; // Revert to the original color
            this.headerStyle.color = 'black'; // Change the background color on scroll
            this.logoSrc = this.defaultLogo;
            this.buttonStyle.backgroundColor = '#2AA1A1';
            this.buttonStyle.color = 'white';

          }
        }
      },
      mounted() {
        window.addEventListener('scroll', this.handleScroll);
      },
      beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
      }
    }

</script>

<style scoped>
  .menu-toggle {
    display: none;
    cursor: pointer;
    font-size: 1.5em;
  }
   .logo img {
     width: 150px;
     height: auto;
     transition: transform 0.3s ease;
     padding: 1rem;
   }

   .logo img:hover {
     transform: scale(1.1);
   }
    .contentNav a{
      color: black;
      text-decoration: none;
      transition: transform 0.3s ease;
      font-weight: bold;

    }
    .contentNav a:hover{
      cursor: pointer;
      transform: scale(1.1);
    }
  .nav-container {
    width: 100%;
    height: 4rem;
    background-color: white;
    display: grid;
    grid-template-columns: 20% 80%;
    position: fixed;
    align-items: center;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
    justify-content: space-between;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
  }

  .contentNav {
    font-size: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 30px;
    position: relative;
    justify-content: end;
  }
    @media screen and (max-width: 800px) {
      .menu-toggle {
        display: block;
        cursor: pointer;
        justify-self: end;
        padding: 2rem;
      }
      .logo img {
        width: 10rem;
        height: 1.5rem;
      }
      .contentNav {
        display: none;
        flex-direction: column;
        top: 60px; /* Adjusted */
        right: 0; /* Changed from left to right */
        width: 100%;
        background-color: #333;
        text-align: center;
        transition: all .3s ease-in-out;
        z-index: 0; /* Adjusted */
        padding-top: 20px;

      }
      .contentNav.active {
        display: flex;
        position: fixed; /* Changed to fixed to ensure it's relative to the viewport */
        z-index: 11; /* Ensure it's above the nav-container */
      }
    }

    @media (min-width: 100px) and (max-width: 400px) {

   }


</style>
