<template>
  <div class="text-section">
    <div class="HeaderTextQuest">
      <h3>Language learning made easy</h3>
    </div>
    <div class="textCtn">
      <p>With Edujad, English would never be hard to learn. With trained teachers in the field of language learning and teaching many methods and approaches that would help you learn and acquire the language in the most effective way. We test your level in the language in order for your teacher to cover the part you need and that you are missing. All you have to do is book an appointment to take your test and select your objective of learning English; and the rest will be on us.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutSection',
  // Add any necessary data, props, etc.
};
</script>
<style scoped>
.text-section {
  display: grid;
  grid-template-columns: 30% 50%;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 0 2rem 0;
  background-color: #2AA1A1;
}

.textCtn {
  flex: 1;
  color: white;
}

.HeaderTextQuest{
  color: black;
}
p{
  padding: 0.5rem;
  text-align: justify;
  line-height: 20pt;
}

</style>