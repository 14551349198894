import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import OffersView from '../views/OffersView.vue'
import ContactView from "@/views/ContactView.vue";
import FeedbackView from "@/views/FeedbackView.vue";
import StorageView from "@/views/StorageView.vue";
import InscriptionView from "@/views/InscriptionView.vue";




const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/offers',
    name: 'offers',
    component: OffersView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: FeedbackView
  },
  {
    path: '/save',
    name: 'save',
    component: StorageView
  },
  {
    path: '/inscription',
    name: 'inscription',
    component: InscriptionView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
