<template>
  <footer>
    <div class="info">
    <div class="logo">
      <router-link to="/"> <img src="../assets/logowhite.png" ></router-link>
    </div>

    <div class="links">
      <router-link class="PLink" :to="{ hash: 'about' }">Offers</router-link>
      <router-link class="PLink" :to="{ hash: 'about' }">Our Teachers</router-link>
      <router-link class="PLink" to="/">About Us</router-link>
    </div>
    </div>
    <div class="social-media">
      <div class="text">
        <p>Contact Us</p>
      </div>
      <div class="icon-social">
        <a href="https://www.facebook.com/edujad.4" class="fa fa-facebook marginfa"></a>
        <a href="https://www.instagram.com/edu_jad/" class="fa fa-instagram marginfa"></a>
        <a href="https://www.linkedin.com/company/edujad-institute/about/?viewAsMember=true" class="fa fa-linkedin"></a>
      </div>
    </div>
  </footer>
</template>
<style scoped>
footer {
  display: grid;
  grid-template-columns: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #474747;
  height: 150px; /* Adjust the height as needed */
  padding: 2.5rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
}
.info{
  display: grid;
  grid-template-columns: 90% 10%;;
}

.logo img {
  height: 40px;
  width: 150px;
}

.links {
  display: grid;
  gap: 1rem;
}
.PLink{
  color: white;
  text-decoration: none;
}
.text p{
  color: white;
}
.social-media {
  display: grid;
  justify-content: center;
  gap: 0.5rem;
}
.marginfa{
  margin-right: 1rem;
}
.icon-social .fa{
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}
.fa:hover {
  opacity: 0.5;
}
.text{
  text-align: center;
}
</style>
<script setup>
</script>