<template>
  <div class="text-section">
    <div class="HeaderTextQuest">
      <h3>A flexible schedule</h3>
    </div>
    <div class="textCtn">
      <p>Facing a busy day? No time to spare for learning? Let us fix that. With Edujad, you can learn the time and the period that fits you - all you have to do is book an appointment or reach out to our support team and we will find a good timing for you.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutSection',
  // Add any necessary data, props, etc.
};
</script>
<style scoped>
.text-section {
  display: grid;
  grid-template-columns: 30% 50%;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 0 2rem 0;
}

.textCtn {
  flex: 1;
}

.HeaderTextQuest{
  color: #2AA1A1;
}
p{
  padding: 0.5rem;
  line-height: 20pt;
  text-align: justify;
}

</style>