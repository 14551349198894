<script setup>

</script>

<template>
  <div class="templateDiv">
    <h3>READY TO JOIN US?</h3>
    <div class="btn">
      <!---<button class="NavBtn">Customize your plan</button>-->
      <router-link to="contact"><button class="NavBtn">Contact us</button></router-link>
    </div>

  </div>

</template>

<style scoped>
.templateDiv{
  display: inline-grid;
  align-content: center;
  margin: 0 0 5rem 0;
}
h3{
  margin: 5rem 2rem 0 2rem;
}
button{
  margin: 2rem;
  height: 70px;
  width: 200px;
  font-size: large;
  cursor: pointer;
  transition: transform 0.3s ease; /* Add a small transition on hover */

}
</style>