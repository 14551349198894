<script setup>

</script>

<template>
  <div>
    <h3> Edujad is the place for language of English learning:</h3>

  </div>
</template>

<style scoped>
h3{
  font-size: 64px;
  padding: 0 3.5rem;

}
</style>