<template>
    <headerMain/>
  <router-link to="/upload">Upload</router-link>
  <router-link to="/feedback">Feedback</router-link>
    <router-view/>
    <footerMain/>
</template>
<script>
  import headerMain from '@/components/headerMain.vue'
  import footerMain from '@/components/footerMain.vue'

  export default {
    components: {
      headerMain,
      footerMain
    }
  }
</script>
<style>
  *{
  margin: 0;
  padding: 0;
}
  button, p, h1, h2, h3, h4, button{
    font-family: "Times New Roman", Times, serif;
  }
#app {
  font-family: "Times New Roman", Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  position: relative;


}

</style>
