<script setup>

</script>

<template>
  <div class="cntmain">
    <div class="textBig">Benefit from flexible offers,
      dependable on your free time,
      and your learning objectives.</div>
    <button class="NavBtn" disabled>Learn more</button>
  </div>

</template>

<style scoped>

.cntmain{
  top: 60px;
  position: static;
  width: 100%;
  height: 80%;
  background-color: white;
  color: black;
  padding: 8rem 0 8rem 0;
  text-align: center;
}
button{
  width: 150px;
  height: 40px;
  font-size: 20px;
}
.textBig{
  font-size: 64px;
  padding: 1rem;
  font-family: Newsreader,serif;
  font-style: italic;
}

@media only screen and (max-width: 600px) {
  .text {
    top: 15%;
    position: absolute;
    color: black;
    padding: 0.5rem;
    text-align: initial;
  }

  .textsmall {
    font-size: 0.5rem;
  }

  .textBig {
    font-size: 1rem;
    padding: 0.5rem 0 0.5rem 0;
  }

}
@media (min-width: 600px) and (max-width: 1000px) {
  .text {
    top: 20%;
    position: absolute;
    color: black;
    padding: 0.5rem;
    text-align: initial;
  }

  .textsmall {
    font-size: 1rem;
  }

  .textBig {
    font-size: 2rem;
    padding: 0.5rem 0 0.5rem 0;
  }
}
</style>