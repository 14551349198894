<template>
  <div class="text-section">
    <div class="HeaderTextQuest">
      <h3>The priority is always YOU</h3>
    </div>
    <div class="textCtn">
      <p>Your teaching is customizable. It is based on your level and your objectives. As a consequence, you will not have to start from scratch, you will start from where you left it. Besides, if your objective is specific, you will be taught lessons authentic to your goal. Moreover, we are always available for any of your questions, in order to offer you support and help.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutSection',
  // Add any necessary data, props, etc.
};
</script>
<style scoped>
.text-section {
  display: grid;
  grid-template-columns: 30% 50%;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 0 2rem 0;
}

.textCtn {
  flex: 1;
}

.HeaderTextQuest{
  color: #2AA1A1;
}
p{
  padding: 0.5rem;
  line-height: 20pt;
  text-align: justify;
}

</style>