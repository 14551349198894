<template>

    <div class="section-image" alt="image"> </div>
</template>

<script>
export default {
  name: 'AboutSection',
  // Add any necessary data, props, etc.
};
</script>
<style scoped>
.section-image {
  background-image: url("../assets/Home1.svg") !important;
  height: 250px;
  background-size: contain;
  background-repeat: no-repeat;
}


</style>