<template>
  <div class="contact-page">
    <h1>Audio Upload for Testing</h1>
    <form @submit.prevent="handleSubmission">
      <label class="name" for="fullname">Full Name:</label>
      <input type="text" id="fullname" name="fullname" v-model="fullname" required>

      <label class="phone" for="phone">Phone:</label>
      <input type="tel" id="phone" name="phone" v-model="phone" required>

      <label class="email" for="email">Email:</label>
      <input type="email" id="email" name="email" v-model="email" required>

      <label class="audio" for="audio">Upload Audio:</label>
      <input type="file" id="audio" @change="handleFileUpload" accept="audio/*" required>

      <button class="NavBtn" type="submit">Submit</button>
      <div v-if="showSuccessNotification" class="notification success">
        <p>{{ successMessage }}</p>
      </div>
      <div v-if="showErrorNotification" class="notification error">
        <p>{{ errorMessage }}</p>
      </div>
    </form>
  </div>
</template>

<script>
import { collection, addDoc, getFirestore } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";

export default {
  name: 'AudioUploadForm',
  data() {
    return {
      fullname: '',
      phone: '',
      email: '',
      audioFile: null,
      showSuccessNotification: false,
      successMessage: 'Audio uploaded successfully. We will email your results ASAP.',
      showErrorNotification: false,
      errorMessage: ''
    };
  },
  methods: {
    handleFileUpload(event) {
      this.audioFile = event.target.files[0];
    },
    async handleSubmission() {
      if (this.audioFile) {
        try {
          const storage = getStorage();
          const storageRef = ref(storage, `audios/${this.audioFile.name}`);

          // Upload audio file to Firebase Storage
          await uploadBytes(storageRef, this.audioFile);
          const downloadURL = await getDownloadURL(storageRef);

          const db = getFirestore();
          const userMessage = {
            fullname: this.fullname,
            phone: this.phone,
            email: this.email,
            audioUrl: downloadURL,
            timestamp: new Date()
          };

          // Save user data and audio file URL to Firestore
          const docRef = await addDoc(collection(db, "audioUploads"), userMessage);
          console.log("Document written with ID: ", docRef.id);

          // Reset form fields
          this.fullname = "";
          this.phone = "";
          this.email = "";
          this.audioFile = null;

          this.showSuccessNotification = true;
          setTimeout(() => {
            this.showSuccessNotification = false;
          }, 3000); // Hide the notification after 3 seconds
        } catch (error) {
          console.error("Error uploading file or saving document: ", error);
          this.errorMessage = `Error: ${error.message}`;
          this.showErrorNotification = true;
          setTimeout(() => {
            this.showErrorNotification = false;
          }, 3000); // Hide the notification after 3 seconds
        }
      } else {
        alert("Please upload an audio file.");
      }
    }
  }
};
</script>

<style scoped>
.contact-page {
  padding: 8rem;
  border-radius: 6px;
  text-align: center;
}

h1 {
  color: #2AA1A1;
}

.NavBtn {
  background-color: #2AA1A1;
  border: 0;
  height: 2.3rem;
  width: 5rem;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 15px;
  color: white;
  cursor: pointer;
}

form {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 1rem;
  background-color: #ffffff;
  border-radius: 5px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 25rem;
}

.notification {
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
}

.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}
</style>