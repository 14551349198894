<template>
  <div class="big">
    <h2>Upload Audio</h2>
    <p><span>Note:</span> Please ensure that your audio is in .wav extension. If it is not, <a href="https://www.freeconvert.com/wav-converter" target="_blank" style="text-decoration: none;">convert it now.</a></p>
    <form @submit.prevent="uploadFile">
      <input ref="fileInput" type="file" accept=".wav" style="display:none" @change="handleFileChange" />
      <button type="button" class="button" @click="triggerFileInput">Choose File</button>
      <span>{{ fileName }}</span>
      <button type="submit" class="button" :disabled="!file">Upload</button>
    </form>
    <div v-if="transcription" class="result">
      <h2>Transcription</h2>
      <p>{{ transcription }}</p>
    </div>
    <div v-if="feedback" class="result">
      <h2>Feedback</h2>
      <p>{{ feedback }}</p>
    </div>
    <div v-if="error" class="result error">
      <h2>Error</h2>
      <p>{{ error }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      file: null,
      transcription: '',
      feedback: '',
      error: ''
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile && selectedFile.type === 'audio/wav') {
        this.file = selectedFile;
        this.fileName = selectedFile.name;
      } else {
        this.file = null;
        this.fileName = '';
        alert('Please select a valid .wav file.');
      }
      console.log("File selected:", this.file);
    },
    async uploadFile() {
      if (!this.file) {
        alert("Please select a file.");
        return;
      }

      const formData = new FormData();
      formData.append('file', this.file);

      try {
        const response = await fetch('http://localhost:5000/upload', {
          method: 'POST',
          body: formData
        });
        const data = await response.json();
        if (response.ok) {
          this.transcription = data.transcription;
          this.feedback = data.feedback;
          this.error = '';
        } else {
          this.error = data.error || "An error occurred.";
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        this.error = "Error uploading file.";
      }
    }
  }
};
</script>

<style>
.big {
margin: 15rem;
font-family: Arial, sans-serif;
color: #333;
text-align: center;
}

h1 {
color: #2AA1A1;
margin-bottom: 2rem;
}

form {
margin-bottom: 2rem;
}

button {
background-color: #2AA1A1;
color: white;
border: none;
padding: 0.75rem 1.5rem;
text-transform: uppercase;
font-size: 1rem;
cursor: pointer;
border-radius: 5px;
margin: 0.5rem;
transition: background-color 0.3s;
}

button:hover {
background-color: #238787;
}

button:disabled {
background-color: #b2d8d8;
cursor: not-allowed;
}

.result {
background-color: #f9f9f9;
padding: 1.5rem;
border-radius: 5px;
margin-top: 1rem;
}

.error {
background-color: #ffcccc;
}

h2 {
color: #2AA1A1;
}

p {
margin: 0.5rem 0;
}
</style>
