import { createApp } from 'vue'
import App from './App.vue'
import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";

import router from './router'
import 'firebase/firestore'
//import firebase from 'firebase/compat/app';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";




const firebaseConfig = {

    apiKey: "AIzaSyBDa9ahEHNntFFVR8s-4x4M5yFih9F6Hkc",
    authDomain: "edujad-e23ac.firebaseapp.com",
    projectId: "edujad-e23ac",
    storageBucket: "edujad-e23ac.appspot.com",
    messagingSenderId: "546955729121",
    appId: "1:546955729121:web:c185102ae2cd983c7abfa0",
    measurementId: "G-LNFKXH81WD"

};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

//const projectFirestore = firebase.firestore()

const db = getFirestore(app);
const storage = getStorage(app);

analytics
db
storage

createApp(App).use(router).mount('#app')


