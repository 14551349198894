<template>
  <div class="text-section">
    <div class="HeaderTextQuest">
      <h3>Nothing matters</h3>
    </div>
    <div class="textCtn">
      <p>Student, professional, mother, father, No matter how old you are, or what background do you have- Edujad will certainly have something for you. We will help you throughout you journey. Sign up and start learning today!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutSection',
  // Add any necessary data, props, etc.
};
</script>
<style scoped>
.text-section {
  display: grid;
  grid-template-columns: 30% 50%;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 0 2rem 0;
  background-color: #2AA1A1;
}

.textCtn {
  flex: 1;
  color: white;
}

.HeaderTextQuest{
  color: black;
}
p{
  padding: 0.5rem;
  text-align: justify;
  line-height: 20pt;

}

</style>